<template>
    <div>
        <page-header :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-md-7 d-flex p-0">
                                <div class="col-10 col-sm-9 form-group">
                                    <input
                                        v-model="newLabel"
                                        class="form-control"
                                        :class="{
                                            'is-invalid': $v.newLabel.$error
                                        }"
                                        placeholder="Label name"
                                    />
                                </div>
                                <div
                                    class="col-3 col-sm-4 form-group d-flex align-items-end"
                                >
                                    <button
                                        class="btn btn-danger waves-effect waves-light"
                                        data-animation="fadein"
                                        data-plugin="custommodal"
                                        data-overlaycolor="#38414a"
                                        @click="createLabelHandler"
                                    >
                                        <i class="mdi mdi-plus-circle mr-1" />
                                        Add Label
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive mb-5">
                            <h5 class="mb-3 text-uppercase"> Labels: </h5>
                            <table
                                id="labels-datatable"
                                class="table table-centered table-striped text-center"
                            >
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="label in labels" :key="label.id">
                                        <td>{{ label.name }}</td>
                                        <td @click.stop>
                                            <a
                                                href="#"
                                                class="action-icon"
                                                @click.prevent="
                                                    showEditModal(label)
                                                "
                                            >
                                                <i class="fas fa-pen mr-1" />
                                            </a>
                                            <a
                                                href="#"
                                                class="action-icon"
                                                @click.prevent="
                                                    showDeleteModal(label)
                                                "
                                            >
                                                <i class="far fa-trash-alt" />
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- end card-body-->
                </div>
                <!-- end card-->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->

        <modal-label-edit
            :modal-title="'Edit Label'"
            :button-text="'Update'"
            :label="labelToUpdate"
            @hidden="hiddenModalLabelEditHandler"
            @updateLabel="updateLabelHandler"
        />

        <confirm-modal
            title="Do you want to delete this label?"
            @agree="deleteLabelHandler"
            @disagree="labelToUpdate = null"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import config from '@config';
import ModalLabelEdit from '@components/labels/ModalLabelEdit';
import ConfirmModal from '@components/elements/ConfirmModal';

export default {
    page: {
        title: 'Labels',
        meta: [{ name: 'description', content: config.description }]
    },

    components: {
        ModalLabelEdit,
        ConfirmModal
    },

    validations: {
        newLabel: {
            required
        }
    },

    data() {
        return {
            title: 'Labels',
            items: [
                {
                    text: 'NovoCare',
                    to: '/'
                },
                {
                    text: 'Users',
                    active: true
                }
            ],
            labels: [],
            newLabel: null,
            labelToUpdate: null,
            selectedLabel: null
        };
    },

    async mounted() {
        await this.fetchLabels();
    },

    methods: {
        ...mapActions({
            getLabels: 'labels/index',
            createLabel: 'labels/store',
            updateLabel: 'labels/update',
            deleteLabel: 'labels/destroy'
        }),

        async fetchLabels() {
            const response = await this.getLabels();

            this.labels = [...response];
            this.selectedLabel = this.labels.length ? this.labels[0].id : null;
        },

        hiddenModalLabelEditHandler() {
            this.labelToUpdate = null;
        },

        showDeleteModal(label) {
            this.labelToUpdate = label;

            this.$bvModal.show('confirmModal');
        },

        showEditModal(label) {
            this.labelToUpdate = label;
            this.$bvModal.show('modalLabelEdit');
        },

        async createLabelHandler() {
            this.$v.newLabel.$touch();

            if (this.$v.newLabel.$invalid) {
                return;
            }

            try {
                const label = await this.createLabel({
                    name: this.newLabel
                });

                this.labels = [...this.labels, label];
                this.newLabel = '';
                this.$v.newLabel.$reset();
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async updateLabelHandler(data) {
            try {
                const response = await this.updateLabel(data);

                this.labels.forEach((element, index) => {
                    if (element.id === response.id) {
                        this.labels[index] = response;
                    }
                });
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async deleteLabelHandler() {
            try {
                await this.deleteLabel(this.labelToUpdate);

                this.labels = this.labels.filter(
                    item => item.id !== this.labelToUpdate.id
                );

                if (this.selectedLabel === this.labelToUpdate.id) {
                    this.selectedLabel = this.labels.length
                        ? this.labels[0].id
                        : null;
                }

                this.labelToUpdate = null;
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        }
    }
};
</script>
