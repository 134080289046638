<template>
    <b-modal
        id="modalLabelEdit"
        class="modal-demo"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        centered
        @show="openModalHandler"
        @hidden="hideModal"
    >
        <template slot="modal-title">
            {{ modalTitle }}
        </template>
        <div class="custom-modal-text text-left">
            <div class="form-group">
                <label for="name"> Name </label>
                <input
                    id="name"
                    v-model="data.name"
                    :class="{ 'is-invalid': $v.data.name.$error }"
                    type="text"
                    class="form-control"
                    placeholder="Name"
                />
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="submitLabelDataHandler"
                >
                    {{ buttonText }}
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    props: {
        updatingLabel: {
            type: Boolean,
            required: false,
            default: true
        },
        label: {
            type: Object,
            required: false,
            default: null
        },
        modalTitle: {
            type: String,
            required: false,
            default: 'Add New Label'
        },
        buttonText: {
            type: String,
            required: false,
            default: 'Save'
        }
    },

    data() {
        return {
            data: {}
        };
    },

    validations: {
        data: {
            name: {
                required
            }
        }
    },

    methods: {
        hideModal() {
            this.$bvModal.hide('modalLabelEdit');
            this.$emit('hidden');
        },

        async openModalHandler() {
            await this.$nextTick();

            if (this.label) {
                return (this.data = { ...this.label });
            }

            this.data = {};
        },

        submitLabelDataHandler() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.$emit('updateLabel', this.data);

            this.$v.$reset();
            this.$bvModal.hide('modalLabelEdit');
        }
    }
};
</script>
